//logo_gallery max 68rem

.logo_gallery {

  .logo_gallery_container {

    .logo_gallery_images {

      .logo_gallery_image_container {
        width: 25%;
        height: calc((25vw - 2rem) / 2);
      }
    }
  }
}