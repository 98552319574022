// bulletpoints max 68rem

.bulletpoints {

  .bulletpoints_container {

    .bulletpoints_items {

      .bulletpoints_item {
        min-width: 45%;
        width: 45%;
        max-width: 45%;
      }
    }
  }
}